import React, { useEffect, useState } from 'react';
import  'bootstrap/dist/css/bootstrap.min.css';
import banner from '../components/partner-detailpage-banner.jpeg';
import {Link, graphql} from 'gatsby'
import Login from './Login';
import loadable from '@loadable/component'
import { Container,Row,Col,Tabs,Tab } from 'react-bootstrap';
// const Footer = loadable(() => import('../components/Footer'))
import Footer  from "../components/Footer";
import Nav from "../components/Nav";

function Partners(partners){
    console.log(partners)
    const  [user, setUser] = useState("")
    const partnersData = partners.data.allMarkdownRemark.edges
    console.log(partnersData, "partners")
    return(

        <>  
        <Nav/>
    <section className=" min-height-400px cover-background top-space-verum" style={{backgroundImage: `url(${banner})`}}>
    <div className="container">
        <div className="row">
            <div className="col-12 col-lg-8 ">
                <h3 className="inner-banner-title text-white">Discover Dezyne together<br/>with our partners</h3>
            </div> 
            <div className="col-12 col-lg-4 " data-wow-delay="0.2s"></div>
        </div>
    </div>
</section>
<section className="padding-70px-top padding-50px-bottom  ">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
                <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Partners</h3>
                <p className="text-large">Verum Software Tools works closely with partners worldwide to provide further reach, service and business opportunities. Interested in becoming a partner? Please fill out the contact form and we will contact you as soon as possible.</p>
            </div>
        </div>
    </div>
</section>


<section className="padding-70px-top padding-50px-bottom bg-light-gray ">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
                <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Solution Partners</h3>
            </div>
        </div>
        <div className="tab-pane med-text fade in active show" id="tab_sec1">
                {partnersData.length > 0 ? (
                    <div className="row mt-4">
                        {partnersData.map((partner) => {
                            if(partner.node.frontmatter.category === 'Solution Partners' && partner.node.frontmatter.image) {
                            return (
                                <div className="col-12 col-md-3 margin-three-bottom text-center">
                                        <img src={partner.node.frontmatter.image} alt="" />
                                </div>
                            )} else {
                                return <></>
                            }
                        })}   
                    </div>
                ) : (
                    <h1 style={{marginTop: "10rem"}}> Partners not available</h1>
                )}
                
            </div>

    </div>
</section>


<section className="bg-light-gray no-padding-top padding-50px-bottom  ">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
                <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Distribution Partners</h3>
            </div>
        </div>
        <Row style={{textAlign: "center"}}>
                  <div >
            <div className="tab-pane med-text fade in active show" id="tab_sec1">
                {partnersData.length > 0 ? (
                    <div className="row mt-4">
                        {partnersData.map((partner) => {
                            if(partner.node.frontmatter.category === 'Distribution Partners' && partner.node.frontmatter.image) {
                            return (
                                <div className="col-12 col-md-3 margin-three-bottom text-center">
                                        <img src={partner.node.frontmatter.image} alt="" />
                                </div>
                            )}
                        })}   
                    </div>
                ) : (
                    <h1 style={{marginTop: "10rem"}}> Partners not available</h1>
                )}
                
            </div></div>
                  </Row>
    </div>

</section>

<section className="no-padding-top padding-50px-bottom bg-light-gray ">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
                <h3 className="title-section alt-font text-dark-gray letter-spacing-1">Technology Partners</h3>
            </div>
        </div>
        <Row style={{textAlign: "center"}}>
                  <div >
            <div className="tab-pane med-text fade in active show" id="tab_sec1">
                {partnersData.length > 0 ? (
                    <div className="row mt-4">
                        {partnersData.map((partner) => {
                            if(partner.node.frontmatter.category === 'Technology Partners' && partner.node.frontmatter.image) {
                            return (
                                <div className="col-12 col-md-3 margin-three-bottom text-center">
                                        <img src={partner.node.frontmatter.category === 'Technology Partners'?partner.node.frontmatter.image: ""} alt="" />
                                </div>
                            )}
                        })}   
                    </div>
                ) : (
                    <h1 style={{marginTop: "10rem"}}> Partners not available</h1>
                )}
                
            </div> </div>
                  </Row>

    </div>
</section>

{/* <section className="padding-70px-bottom no-padding-top bg-light-gray ">
    <div className="container tab-style2">
        <div className="row">
            <div className="col-12">
                <ul className="nav nav-tabs alt-font text-large text-center font-weight-700 justify-content-center">
                    <li className="nav-item"><a className="nav-link active" href="#tab_sec1" data-toggle="tab">Solution Partners</a></li>
                    <li className="nav-item"><a className="nav-link" href="#tab_sec2" data-toggle="tab">Distribution Partners</a></li>
                    <li className="nav-item"><a className="nav-link" href="#tab_sec3" data-toggle="tab">Technology Partners</a></li>
                </ul>
            </div>
        </div>                                
        <div className="tab-content">
            <div className="tab-pane med-text fade in active show" id="tab_sec1">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center">
                        <p>Verum Software Tools works closely with solution partners worldwide to provide further reach, service and business opportunities. Here you find the contact information of our solution partners. Are you located in a region without solution partner? <a href="#" className="text-green">Contact us</a>, we will gladly inform you about Dezyne.</p>
                    </div>
                </div>
                {partnersData.length > 0 ? (
                    <div className="row justify-content-center mt-4">
                        {partnersData.map((partner) => {
                            return (
                                <div className="col-12 col-md-3 margin-three-bottom text-center">
                                    <Link to={partner.node.frontmatter.name}>
                                        <img src={partner.node.frontmatter.category === 'Solution Partners'?partner.node.frontmatter.image: ""} alt="" style={{maxWidth: "223px", maxHeight: "512px"}}/>
                                    </Link>
                                </div>
                            )
                        })}   
                    </div>
                ) : (
                    <h1 style={{marginTop: "10rem"}}> Partners not available</h1>
                )}
                
            </div>
            <div className="tab-pane fade in" id="tab_sec2">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center">
                        <p>Verum Software Tools works closely with distribution partners worldwide to provide further reach, service and business opportunities. Here you find the contact information of our distribution partners. Are you located in a region without distribution partner? <a href="#" className="text-green">Contact us</a>, we will gladly inform you about Dezyne.</p>
                    </div>
                </div>
                {partnersData.length > 0 ? (
                    <div className="mt-4">
                        {partnersData.map((partner) => {
                            return (
                                <div className="col-12 col-md-3 margin-three-bottom text-center">
                                    <Link to={partner.node.frontmatter.name}>
                                        <img src={partner.node.frontmatter.category === 'Distribution Partners'?partner.node.frontmatter.image: ""} alt="" style={{maxWidth: "223px", maxHeight: "512px"}}/>
                                    </Link>
                                </div>
                            )
                        })}   
                    </div>
                ) : (
                    <h1 style={{marginTop: "10rem"}}> Partners not available</h1>
                )}
            </div>
            <div className="tab-pane fade in" id="tab_sec3">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center">
                        <p>Verum Software Tools works closely with technology partners worldwide to provide further reach, service and business opportunities. Here you find the contact information of our technology partners. Are you located in a region without technology partner? <a href="#" className="text-green">Contact us</a>, we will gladly inform you about Dezyne.</p>
                    </div>
                </div>
                {partnersData.length > 0 ? (
                    <div className="mt-4">
                        {partnersData.map((partner) => {
                            return (
                                <div className="col-12 col-md-3 margin-three-bottom text-center">
                                    <Link to={partner.node.frontmatter.name}>
                                        <img src={partner.node.frontmatter.category === 'Technology Partners'?partner.node.frontmatter.image: ""} alt="" style={{maxWidth: "223px", maxHeight: "512px"}}/>
                                    </Link>
                                </div>
                            )
                        })}   
                    </div>
                ) : (
                    <h1 style={{marginTop: "10rem"}}> Partners not available</h1>
                )}
            </div>
        </div>                               
    </div>
</section> */}
<Footer/></>)
}
export default Partners

export const PartnerQuery =graphql`
query PartnersQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/Partners/)/"}},sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              name
              image
              category
            }
          }
        }
      }
}
`